import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

const Masonry = require('masonry-layout')

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  scrollSpy('.header-nav-sub:visible', { offset: 0 })

  // if (window.location.pathname == '/') {
  //   smoothScroll('a[href*="/#"]', { offset: 130 })
  // }
})

$(document).ready(function () {
  $('.card-header').click(function () {
    $(this).prev().toggle()
    const button = $(this).find('.read-more')
    if ($(button).text() === 'Mehr erfahren') {
      $(button).text('Weniger lesen')
    } else {
      $(button).text('Mehr erfahren')
    }
  })
})

document.querySelectorAll('[style*="box-shadow"]').forEach(el => {
  el.classList.add('has-shadow')
})

document.querySelectorAll('.grid-content').forEach(el => {
  if (getComputedStyle(el).color === 'rgb(255, 255, 255)') { // rgba(255,255,255,1) wird als rgb(255,255,255) ausgegeben
      el.classList.add('has-white-color');
  }
});
